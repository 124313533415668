'use strict';

import {Popup} from '../popup';
import {sessid} from "../common";
import {lang} from "../common";
require('inputmask');

class ReportAvailability extends Popup {
	constructor() {
		super('availability');
		this.requestProcessing = false;
	}

	set url(path) {
		this._url = path;
	}

	get url() {
		return this._url;
	}

	set productId(id) {
		this._productId = id;
	}

	get productId() {
		return this._productId;
	}

    set langId(id) {
        this._langId = id;
    }

    get langId() {
        return this._langId;
    }

	set messages(messages) {
		this._messages = messages;
	}

	get messages() {
		return this._messages;
	}

	set requestProcessing(processing) {
		this._requestProcessing = processing;
	}

	get requestProcessing() {
		return this._requestProcessing;
	}

	loadData(body, url) {
		body.sessid = sessid;
		body.lang = lang;
		let data = new FormData();
		for (let key in body) {
			if (!body.hasOwnProperty(key)) continue;
			data.append(key, body[key]);
		}
		return fetch((url) ? url : this.url, {method: "POST", body: data, headers: {"X-Requested-With": "XMLHttpRequest"}});
	}

	checkHasValue(input) {
		(input.value.length > 0) ? input.classList.add('has-value') : input.classList.remove('has-value');
		return this;
	}

	validateField(input) {
		let action = `${input.dataset.validate}Validate`;
		let valid = this[action](input);
		if (!valid) this.showFieldError(input);
		else this.hideFieldError(input);
		return valid;
	}

	validateForm(event) {
		let valid = true, self = this;
		[].slice.apply(this.body.querySelectorAll('input[data-validate]')).forEach(input => {
			if (!self.validateField(input)) valid = false;
		});
		return valid;
	}

	nameValidate(input) {
		return /^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ]+([ʼ'-][a-zA-ZА-Яа-яЁёЇїІіЄєҐґ]+)?(\s[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ']+)?$/g.test(input.value);
	}

	emailValidate(input) {
		return (/^([a-zA-Z0-9_.+-])+(\@([a-zA-Z0-9-])+\.)+.+([a-zA-Z0-9])+$/.test(input.value));
	}

	phoneValidate(input) {
        return input.value.replace(/[-_)(]/g, '').length === 13;
	}

	initEvents() {
		let self = this;
		let phoneInputMask = this.body.querySelectorAll("[name=ORDER_PROP_3]");
		var im = new Inputmask("+380(99)999-99-99");
		im.mask(phoneInputMask);

		// User input change value
		this.body.addEventListener('change', e => {
			// Check field valid
			self.validateField(e.target);
			self.checkHasValue(e.target);
		});
		// User send report form
		this.body.addEventListener('submit', e => {
			e.preventDefault();
			// Drop if request processing
			if (self.requestProcessing) return;
			// validate and send order data
			if (self.validateForm(e)) self.sendOrderData(e);
		});
	}

	getherFormValues(form) {
		let values = {};
		[].slice.apply(form.querySelectorAll('input')).forEach(input => {
			values[input.getAttribute('name')] = input.value;
		});
		return values;
	}

	sendOrderData(event) {
		let self =this;
		// Form values
		let body = this.getherFormValues(event.target);
		// Set product id
		body.productId = this.productId;
		this.requestProcessing = true;
		this.loadData(body, this.url + '?via-ajax=Y&is_ajax_post=Y')
			.then(response => {
				return response.json();
			})
			.then(data => {
				if (data.order.ID) {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({'event': 'predzakaz'});
					self.body = self.renderSuccess();
					return self.loadData({"order": data.order.ID, "status": "G"}, '/personal/orderstatuschange.php');
				}
			})
			.then(response => {
				return response.json();
			})
			.then(data => {
				console.dir(data);
			});
		return this;
	}

	message(key) {
		return (this.messages.hasOwnProperty(key)) ? this.messages[key] : 'Text not defined.';
	}

	showFieldError(input) {
		input.parentElement.classList.add('error');
		return this;
	}

	hideFieldError(input) {
		input.parentElement.classList.remove('error');
		return this;
	}

	renderFields() {
		return `<div class="modal-form-wrap">
            	<div class="modal-form-holder">
            		<div class="form__modal">
									<div class="modal-form">
											<span class="modal-title">${this.message('SOA_REPORT_AVAILABILITY')}</span>
											<span class="modal-text">${this.message('SOA_REPORT_AVAILABILITY_TITLE')}</span>
											<form class="form_validate form-with-thx" method="POST">
												<input type="hidden" name="ORDER_PROP_41" value="${this.message('SOA_REPORT_AVAILABILITY_PROP')}">
												<input type="hidden" name="ORDER_PROP_50" value="${this.langId}">
												<input type="hidden" name="PAY_SYSTEM_ID" value="1">
												<input type="hidden" name="DELIVERY_ID" value="4">
												<input type="hidden" name="PERSON_TYPE" value="1">
												<input type="hidden" name="PERSON_TYPE_OLD" value="1">
												<input type="hidden" name="ORDER_DESCRIPTION" value="${this.message('SOA_REPORT_AVAILABILITY_PROP')}">
												<input type="hidden" name="action" value="saveOrderAjax">
												<div class="row">
														<div class="input-holder field">
																<input type="text" class="input form-control js-input" name="ORDER_PROP_1" data-validate="name">
																<span class="label-input">${this.message('SOA_REPORT_AVAILABILITY_NAME')}</span>
																<span class="error-text">${this.message('SOA_REPORT_AVAILABILITY_NAME_ERROR')}</span>
														</div>
												</div>
												<div class="row">
														<div class="input-holder field phone">
																<input type="tel" class="input form-control js-input validate_phone" name="ORDER_PROP_3" placeholder="+380(__)___-__-__" data-validate="phone">
																<span class="label-input phone">${this.message('SOA_REPORT_AVAILABILITY_PHONE')}</span>
																<span class="error-text">${this.message('SOA_REPORT_AVAILABILITY_USER_DATA_ERROR')}</span>
														</div>
												</div>
												<div class="row">
														<div class="input-holder field email">
																<input type="text" class="input form-control js-input" name="ORDER_PROP_2" placeholder="_@_._" data-validate="email">
																<span class="label-input email">${this.message('SOA_REPORT_AVAILABILITY_EMAIL')}</span>
																<span class="error-text">${this.message('SOA_REPORT_AVAILABILITY_USER_DATA_ERROR')}</span>
														</div>
												</div>
												<div class="row submit-holder">
														<input type="submit" class="btn btn-submit" name="save" value="${this.message('SOA_REPORT_AVAILABILITY_SEND')}">
												</div>
											</form>
									</div>
								</div>
							</div>
						</div>`;
	}

	renderSuccess() {
		return `<div class="modal-form-wrap">
            	<div class="modal-form-holder">
            		<div class="form__modal">
									<div class="success-send-form" style="display: block;">
										<div class="row">
												<div class="modal-title">
														<span>${this.message('SOA_REPORT_AVAILABILITY_SUCCESS_TITLE')}</span>
												</div>					
										</div>
										<div class="row">						
												<span class="modal-text">${this.message('SOA_REPORT_AVAILABILITY_SUCCESS_TEXT')}</span>							
										</div>	
										<div class="row row-centered"><span class="ic-success"></span></div>				
									</div>
								</div>
							</div>
						</div>`;
	}
}

export default ReportAvailability;